// admin.domain.com
import React from "react";

//
function AdminPage() {
  return (
    <>
      <p>Admin Management</p>
      <p>Coming soon!</p>
    </>
  );
}

export default AdminPage;
