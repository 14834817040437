//
function Link() {
  //
  return {
    // className of MUI
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
  };
}

export default Link;
