import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Pagination, Stack, Typography } from "@mui/material";

import { getComments } from "./commentSlice";
import CommentCard from "./CommentCard";
import LoadingScreen from "../../components/LoadingScreen";
import { COMMENTS_PER_POST } from "../../app/config";

// lay comments theo post
function CommentList({ postId }) {
  const {
    commentsByPost,
    totalComments,
    currentPage,
    commentsById,
    isLoading,
  } = useSelector(
    (state) => ({
      commentsByPost: state.comment.commentsByPost[postId],
      totalComments: state.comment.totalCommentsByPost[postId] || 0,
      currentPage: state.comment.currentPageByPost[postId] || 1,
      commentsById: state.comment.commentsById,
      isLoading: state.comment.isLoading,
    }),
    shallowEqual // update data for render UI
  );

  const totalPages = Math.ceil(totalComments / COMMENTS_PER_POST);

  //
  const dispatch = useDispatch();
  useEffect(() => {
    if (postId) dispatch(getComments({ postId }));
  }, [postId, dispatch]);

  // render list of comments
  let renderComments;

  if (commentsByPost) {
    const comments = commentsByPost.map((commentId) => commentsById[commentId]);
    renderComments = (
      <Stack spacing={1.5}>
        {comments.map((comment) => (
          <CommentCard key={comment?._id} comment={comment} />
        ))}
      </Stack>
    );
  } else if (isLoading) {
    renderComments = <LoadingScreen />;
  }

  //
  return (
    <>
      <Stack spacing={1.5}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="subtitle" sx={{ color: "text.secondary" }}>
            {totalComments > 1
              ? `${totalComments} comments`
              : totalComments === 1
              ? `${totalComments} comment`
              : "No comment"}
          </Typography>

          {totalComments > COMMENTS_PER_POST && (
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(e, page) => dispatch(getComments({ postId, page }))}
            />
          )}
        </Stack>
        {/*  */}
        {renderComments}
      </Stack>
    </>
  );
}

export default CommentList;
